<template>
  <b-container fluid v-can="'products.update'">
    <b-row>
      <b-col md="12" lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Editar Producto</h4>
          </template>
          <template v-slot:body>
            <div class="new-user-info">
              <update origin="0" :data="getProduct"/>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import Update from "./forms/createOrUpdate.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RegisterProducts",
  components: {
    Update,
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    await this.showProducts(this.$route.params.id);
    core.index();
  },
  data() {
    return {
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Listado De Productos",
          to: "/products/list",
        },
        {
          text: "Editar Producto",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      showProducts: "products/showProducts",
    }),
  },
  computed: {
    ...mapGetters({
      getProduct: "products/getProduct",
    }),
  },
};
</script>
<style>
.vs__clear {
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
</style>
